<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <!--    <div class="row">-->
    <!--      <div class="col-md-6">-->
    <div class="row">
      <div class="col-sm-12">
        <input
          class="form-control"
          name="search"
          id="search"
          placeholder="2 character country code or country name"
          v-model="mysearch"
        />
      </div>
    </div>
    <div class="row">
      <div
        v-for="(value, key) in countryListFilter"
        :item="value"
        :key="key"
        :data-no="value.no"
        :data-name="value.name"
        @click="countryDetailClick"
        class="col-lg-2 col-md-3 cur-pointer text-hover-secondary bg-hover-light"
        data-bs-toggle="modal"
        data-bs-target="#detailCountryModal"
      >
        <!-- <country-flag :country="value.code" /> <span> {{ value.name }}</span> -->
        <img :src="'/img/flags/24/' + value.code + '.png'" :alt="value.name" class="img-fluid" />
        <span
          v-if="$data.mysearch.length == 2 && $data.mysearch == value.code"
          :class="{ 'text-danger': $data.mysearch.length == 2 && $data.mysearch == value.code }"
          v-html="value.name"
        >
        </span>
        <span
          v-else
          :class="{ 'text-danger': $data.mysearch.length == 2 && $data.mysearch == value.code }"
          v-html="hightlight(value.name)"
        >
        </span>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="detailCountryModal"
    tabindex="-1"
    aria-labelledby="detailModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header py-0">
          <h2 class="modal-title text-secondary" id="detailModalLabel">
            Detail about country {{ $data.detail.selectedName }}
          </h2>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="$data.ajax.isLoadDetail">
            <LoadingIcons total="8" />
          </div>

          <div v-else>
            <h3 class="text-primary">Fun facts about {{ $data.detail.selectedName }}</h3>
            <div class="row">
              <div v-for="(item, i) in $data.detail.facts" class="col-md-6" :key="i">
                <b>{{ item['title'] }}</b> : {{ item['value'] }}
              </div>
            </div>
          </div>
          <h3 class="text-primary">{{ $data.detail.selectedName }} Import Tax & Custom Fees</h3>
          <div class="row">
            <div class="col-sm-12" v-html="$data.detail.taxCommon.intro"></div>
          </div>
          <h3 class="text-primary">{{ $data.detail.selectedName }} DUTIES & TAX DETAILS</h3>
          <dl class="row">
            <dt class="col-sm-3">Currency</dt>
            <dd class="col-sm-9">{{ $data.detail.taxCommon.currency_name }}</dd>
            <dt class="col-sm-3">Calculation Method</dt>
            <dd class="col-sm-9">
              {{ $data.detail.taxCommon.caculation_method }}
              <br />
              <small class="text-muted"
                >Tax & duties are calculated on the price of the products and shipping costs</small
              >
            </dd>
            <dt class="col-sm-3">Tax Threshold</dt>
            <dd class="col-sm-9">
              {{ $data.detail.taxCommon.tax_threshold }} {{ $data.detail.taxCommon.currency_code }}
            </dd>
            <dt class="col-sm-3">Tax (VAT/GST)</dt>
            <dd class="col-sm-9">{{ $data.detail.taxCommon.tax }} %</dd>
            <dt class="col-sm-3">Duty Threshold</dt>
            <dd class="col-sm-9">
              {{ $data.detail.taxCommon.duty_threshold }} {{ $data.detail.taxCommon.currency_code }}
            </dd>
          </dl>
          <h4 class="text-primary" v-if="$data.detail.dutyCategory.length > 0">
            Import Duty Product Categories
          </h4>
          <small class="text-muted" v-if="$data.detail.dutyCategory.length > 0"
            >Shipment items in an order will have relevant duties charged at this rate</small
          >
          <div class="row" v-if="$data.detail.dutyCategory.length > 0">
            <div class="col-md-4 col-lg-3" v-for="(item, i) in $data.detail.dutyCategory" :key="i">
              <dl class="row">
                <dt class="col-sm-9">{{ item.name }}</dt>
                <dd class="col-sm-3 text-end">{{ item.value }}%</dd>
              </dl>
            </div>
          </div>
          <h3 class="text-primary" v-if="$data.detail.restrictedItems.length > 0">
            {{ $data.detail.selectedName }} Shipping Restrictions Items
          </h3>
          <div class="row" v-if="$data.detail.restrictedItems.length > 0">
            <div class="col-md-3" v-for="(item, i) in $data.detail.restrictedItems" :key="i">
              <i class="fa fa-exclamation-triangle text-warning"></i> {{ item.name }}
            </div>
          </div>
          <h3 class="text-primary" v-if="$data.detail.restrictedItems.length > 0">
            {{ $data.detail.selectedName }} Shipping Prohibited Items
          </h3>
          <div class="row" v-if="$data.detail.restrictedItems.length > 0">
            <div class="col-md-3" v-for="(item, i) in $data.detail.prohibitedItems" :key="i">
              <i class="fa fa-ban text-danger"></i> {{ item.name }}
            </div>
          </div>
        </div>

        <!--        <div class="modal-footer py-0">-->
        <!--          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
// import CountryFlag from 'vue-country-flag-next';
// import MyCountry from './MyCountry';
// import TextHighlight from 'vue-text-highlight';
// Vue.component('text-highlight', TextHighlight);
import LoadingIcons from '../../common/LoadingIcons.vue';
export default {
  name: 'CustomsExciseDuty',
  data: function () {
    return {
      mysearch: '',
      // countryList: [],
      detail: {
        selectedName: '',
        facts: [],
        taxCommon: [],
        dutyCategory: [],
        prohibitedItems: [],
        restrictedItems: [],
      },
      ajax: {
        isLoadDetail: false,
      },
    };
  },
  components: {
    // CountryFlag,
    // MyCountry,
    LoadingIcons,
  },
  methods: {
    // formatDetail: function (index, str) {
    //   if (index == 0) {
    //     let tmp = str.split(' ');
    //     let tmpNumber = tmp[1].substring(0, tmp[1].length - 1);
    //     let tmpUnit = tmp[1].substring(tmp[1].length - 1, tmp[1].length);
    //     return ': <span>' + tmp[0] + '</span> ' + tmpNumber + '<span>' + tmpUnit + '</span>';
    //   } else {
    //     let tmpNumber = str.substring(0, str.length - 1);
    //     let tmpUnit = str.substring(str.length - 1, str.length);
    //     return ': ' + tmpNumber + ' <span>' + tmpUnit + '</span>';
    //   }
    // },
    countryDetailClick: async function (e) {
      this.$data.detail.facts = [];
      this.$data.detail.taxCommon = [];
      this.$data.detail.dutyCategory = [];
      this.$data.detail.prohibitedItems = [];
      this.$data.detail.restrictedItems = [];
      this.$data.ajax.isLoadDetail = true;

      let no = e.currentTarget.getAttribute('data-no');
      this.$data.detail.selectedName = e.currentTarget.getAttribute('data-name');
      let self = this;
      let apiUrl = process.env.VUE_APP_API_URL;
      let data = {
        no: no,
        name: e.currentTarget.getAttribute('data-name'),
      };

      const formData = Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
      await fetch(apiUrl + 'common/custom-country-detail', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          console.log('Success:', resData);
          // let messages = resData.messages
          if (resData.status == 'error') {
            alert('error while geting data ' + resData.message);
            return;
          }
          self.$data.detail.facts = resData.facts;
          self.$data.detail.taxCommon = resData.taxCommon;
          self.$data.detail.dutyCategory = resData.dutyCategory;
          let tmpRestrict = [],
            tmpProhibit = [];
          for (let i = 0; i < resData.prohibitedRestricted.length; i++) {
            if (resData.prohibitedRestricted[i].type == 1) {
              tmpProhibit.push(resData.prohibitedRestricted[i]);
            } else {
              tmpRestrict.push(resData.prohibitedRestricted[i]);
            }
          }
          // prohibitedItems: [],
          //     restrictedItems: [],
          self.$data.detail.prohibitedItems = tmpProhibit;
          self.$data.detail.restrictedItems = tmpRestrict;
          self.$data.ajax.isLoadDetail = false;
        })
        .catch(function (err) {
          console.log('error', err);
          self.$data.ajax.isLoadDetail = false;
        });
      // this.$data.detail.selectedName = 'Detail about country : ' + name;
    },
    hightlight: function (term) {
      return term.replaceAll(
        new RegExp(this.$data.mysearch, 'gi'),
        (match) => `<span class="text-primary">${match}</span>`
      );
    },
  },
  computed: {
    //eslint-disable-next-line
    countryListFilter: function () {
      let countryList = [
        {
          no: 1,
          name: 'Afghanistan',
          code: 'af',
        },
        {
          no: 249,
          name: 'Åland Islands',
          code: 'ax',
        },
        {
          no: 2,
          name: 'Albania',
          code: 'al',
        },
        {
          no: 3,
          name: 'Algeria',
          code: 'dz',
        },
        {
          no: 4,
          name: 'American Samoa',
          code: 'as',
        },
        {
          no: 5,
          name: 'Andorra',
          code: 'ad',
        },
        {
          no: 6,
          name: 'Angola',
          code: 'ao',
        },
        {
          no: 7,
          name: 'Anguilla',
          code: 'ai',
        },
        {
          no: 8,
          name: 'Antarctica',
          code: 'aq',
        },
        {
          no: 9,
          name: 'Antigua and Barbuda',
          code: 'ag',
        },
        {
          no: 10,
          name: 'Argentina',
          code: 'ar',
        },
        {
          no: 11,
          name: 'Armenia',
          code: 'am',
        },
        {
          no: 12,
          name: 'Aruba',
          code: 'aw',
        },
        {
          no: 13,
          name: 'Australia',
          code: 'au',
        },
        {
          no: 14,
          name: 'Austria',
          code: 'at',
        },
        {
          no: 15,
          name: 'Azerbaijan',
          code: 'az',
        },
        {
          no: 16,
          name: 'Bahamas',
          code: 'bs',
        },
        {
          no: 17,
          name: 'Bahrain',
          code: 'bh',
        },
        {
          no: 18,
          name: 'Bangladesh',
          code: 'bd',
        },
        {
          no: 19,
          name: 'Barbados',
          code: 'bb',
        },
        {
          no: 20,
          name: 'Belarus',
          code: 'by',
        },
        {
          no: 21,
          name: 'Belgium',
          code: 'be',
        },
        {
          no: 22,
          name: 'Belize',
          code: 'bz',
        },
        {
          no: 23,
          name: 'Benin',
          code: 'bj',
        },
        {
          no: 24,
          name: 'Bermuda',
          code: 'bm',
        },
        {
          no: 25,
          name: 'Bhutan',
          code: 'bt',
        },
        {
          no: 26,
          name: 'Bolivia',
          code: 'bo',
        },
        {
          no: 27,
          name: 'Bonaire, Sint Eustatius and Saba',
          code: 'bq',
        },
        {
          no: 28,
          name: 'Bosnia and Herzegovina',
          code: 'ba',
        },
        {
          no: 29,
          name: 'Botswana',
          code: 'bw',
        },
        {
          no: 30,
          name: 'Bouvet Island',
          code: 'bv',
        },
        {
          no: 31,
          name: 'Brazil',
          code: 'br',
        },
        {
          no: 32,
          name: 'British Indian Ocean Territory',
          code: 'io',
        },
        {
          no: 33,
          name: 'Brunei Darussalam',
          code: 'bn',
        },
        {
          no: 34,
          name: 'Bulgaria',
          code: 'bg',
        },
        {
          no: 35,
          name: 'Burkina Faso',
          code: 'bf',
        },
        {
          no: 36,
          name: 'Burundi',
          code: 'bi',
        },
        {
          no: 59,
          name: 'Ivory Coast',
          code: 'ci',
        },
        {
          no: 37,
          name: 'Cambodia',
          code: 'kh',
        },
        {
          no: 38,
          name: 'Cameroon',
          code: 'cm',
        },
        {
          no: 39,
          name: 'Canada',
          code: 'ca',
        },
        {
          no: 40,
          name: 'Cape Verde',
          code: 'cv',
        },
        {
          no: 41,
          name: 'Cayman Islands',
          code: 'ky',
        },
        {
          no: 42,
          name: 'Central African Republic',
          code: 'cf',
        },
        {
          no: 43,
          name: 'Chad',
          code: 'td',
        },
        {
          no: 44,
          name: 'Chile',
          code: 'cl',
        },
        {
          no: 45,
          name: 'China',
          code: 'cn',
        },
        {
          no: 46,
          name: 'Christmas Island',
          code: 'cx',
        },
        {
          no: 47,
          name: 'Cocos (Keeling) Islands',
          code: 'cc',
        },
        {
          no: 48,
          name: 'Colombia',
          code: 'co',
        },
        {
          no: 49,
          name: 'Comoros',
          code: 'km',
        },
        {
          no: 50,
          name: 'Congo',
          code: 'cg',
        },
        {
          no: 52,
          name: 'Cook Islands',
          code: 'ck',
        },
        {
          no: 53,
          name: 'Costa Rica',
          code: 'cr',
        },
        {
          no: 54,
          name: 'Croatia',
          code: 'hr',
        },
        {
          no: 55,
          name: 'Cuba',
          code: 'cu',
        },
        {
          no: 56,
          name: 'Curacao',
          code: 'cw',
        },
        {
          no: 57,
          name: 'Cyprus',
          code: 'cy',
        },
        {
          no: 58,
          name: 'Czech Republic',
          code: 'cz',
        },
        {
          no: 60,
          name: 'Denmark',
          code: 'dk',
        },
        {
          no: 61,
          name: 'Djibouti',
          code: 'dj',
        },
        {
          no: 62,
          name: 'Dominica',
          code: 'dm',
        },
        {
          no: 63,
          name: 'Dominican Republic',
          code: 'do',
        },
        {
          no: 64,
          name: 'Ecuador',
          code: 'ec',
        },
        {
          no: 65,
          name: 'Egypt',
          code: 'eg',
        },
        {
          no: 66,
          name: 'El Salvador',
          code: 'sv',
        },
        {
          no: 67,
          name: 'Equatorial Guinea',
          code: 'gq',
        },
        {
          no: 68,
          name: 'Eritrea',
          code: 'er',
        },
        {
          no: 69,
          name: 'Estonia',
          code: 'ee',
        },
        {
          no: 213,
          name: 'Eswatini',
          code: 'sz',
        },
        {
          no: 70,
          name: 'Ethiopia',
          code: 'et',
        },
        {
          no: 71,
          name: 'Falkland Islands (Malvinas)',
          code: 'fk',
        },
        {
          no: 72,
          name: 'Faroe Islands',
          code: 'fo',
        },
        {
          no: 73,
          name: 'Fiji',
          code: 'fj',
        },
        {
          no: 74,
          name: 'Finland',
          code: 'fi',
        },
        {
          no: 75,
          name: 'France',
          code: 'fr',
        },
        {
          no: 76,
          name: 'French Guiana',
          code: 'gf',
        },
        {
          no: 77,
          name: 'French Polynesia',
          code: 'pf',
        },
        {
          no: 78,
          name: 'French Southern Territories',
          code: 'tf',
        },
        {
          no: 79,
          name: 'Gabon',
          code: 'ga',
        },
        {
          no: 80,
          name: 'Gambia',
          code: 'gm',
        },
        {
          no: 81,
          name: 'Georgia',
          code: 'ge',
        },
        {
          no: 82,
          name: 'Germany',
          code: 'de',
        },
        {
          no: 83,
          name: 'Ghana',
          code: 'gh',
        },
        {
          no: 84,
          name: 'Gibraltar',
          code: 'gi',
        },
        {
          no: 85,
          name: 'Greece',
          code: 'gr',
        },
        {
          no: 86,
          name: 'Greenland',
          code: 'gl',
        },
        {
          no: 87,
          name: 'Grenada',
          code: 'gd',
        },
        {
          no: 88,
          name: 'Guadeloupe',
          code: 'gp',
        },
        {
          no: 89,
          name: 'Guam',
          code: 'gu',
        },
        {
          no: 90,
          name: 'Guatemala',
          code: 'gt',
        },
        {
          no: 91,
          name: 'Guernsey',
          code: 'gg',
        },
        {
          no: 92,
          name: 'Guinea',
          code: 'gn',
        },
        {
          no: 93,
          name: 'Guinea-Bissau',
          code: 'gw',
        },
        {
          no: 94,
          name: 'Guyana',
          code: 'gy',
        },
        {
          no: 95,
          name: 'Haiti',
          code: 'ht',
        },
        {
          no: 96,
          name: 'Heard and McDonald Islands',
          code: 'hm',
        },
        {
          no: 97,
          name: 'Holy See (Vatican City State)',
          code: 'va',
        },
        {
          no: 98,
          name: 'Honduras',
          code: 'hn',
        },
        {
          no: 99,
          name: 'Hong Kong',
          code: 'hk',
        },
        {
          no: 100,
          name: 'Hungary',
          code: 'hu',
        },
        {
          no: 101,
          name: 'Iceland',
          code: 'is',
        },
        {
          no: 102,
          name: 'India',
          code: 'in',
        },
        {
          no: 103,
          name: 'Indonesia',
          code: 'id',
        },
        {
          no: 104,
          name: 'Iran',
          code: 'ir',
        },
        {
          no: 105,
          name: 'Iraq',
          code: 'iq',
        },
        {
          no: 106,
          name: 'Ireland',
          code: 'ie',
        },
        {
          no: 107,
          name: 'Isle of Man',
          code: 'im',
        },
        {
          no: 108,
          name: 'Israel',
          code: 'il',
        },
        {
          no: 109,
          name: 'Italy',
          code: 'it',
        },
        {
          no: 110,
          name: 'Jamaica',
          code: 'jm',
        },
        {
          no: 111,
          name: 'Japan',
          code: 'jp',
        },
        {
          no: 112,
          name: 'Jersey',
          code: 'je',
        },
        {
          no: 113,
          name: 'Jordan',
          code: 'jo',
        },
        {
          no: 114,
          name: 'Kazakhstan',
          code: 'kz',
        },
        {
          no: 115,
          name: 'Kenya',
          code: 'ke',
        },
        {
          no: 116,
          name: 'Kiribati',
          code: 'ki',
        },
        {
          no: 117,
          name: 'Kuwait',
          code: 'kw',
        },
        {
          no: 118,
          name: 'Kyrgyzstan',
          code: 'kg',
        },
        {
          no: 119,
          name: 'Laos',
          code: 'la',
        },
        {
          no: 120,
          name: 'Latvia',
          code: 'lv',
        },
        {
          no: 121,
          name: 'Lebanon',
          code: 'lb',
        },
        {
          no: 122,
          name: 'Lesotho',
          code: 'ls',
        },
        {
          no: 123,
          name: 'Liberia',
          code: 'lr',
        },
        {
          no: 124,
          name: 'Libya',
          code: 'ly',
        },
        {
          no: 125,
          name: 'Liechtenstein',
          code: 'li',
        },
        {
          no: 126,
          name: 'Lithuania',
          code: 'lt',
        },
        {
          no: 127,
          name: 'Luxembourg',
          code: 'lu',
        },
        {
          no: 128,
          name: 'Macao',
          code: 'mo',
        },
        {
          no: 129,
          name: 'Madagascar',
          code: 'mg',
        },
        {
          no: 130,
          name: 'Malawi',
          code: 'mw',
        },
        {
          no: 131,
          name: 'Malaysia',
          code: 'my',
        },
        {
          no: 132,
          name: 'Maldives',
          code: 'mv',
        },
        {
          no: 133,
          name: 'Mali',
          code: 'ml',
        },
        {
          no: 134,
          name: 'Malta',
          code: 'mt',
        },
        {
          no: 135,
          name: 'Marshall Islands',
          code: 'mh',
        },
        {
          no: 136,
          name: 'Martinique',
          code: 'mq',
        },
        {
          no: 137,
          name: 'Mauritania',
          code: 'mr',
        },
        {
          no: 138,
          name: 'Mauritius',
          code: 'mu',
        },
        {
          no: 139,
          name: 'Mayotte',
          code: 'yt',
        },
        {
          no: 140,
          name: 'Mexico',
          code: 'mx',
        },
        {
          no: 141,
          name: 'Micronesia, Federated States Of',
          code: 'fm',
        },
        {
          no: 142,
          name: 'Moldova, Republic of',
          code: 'md',
        },
        {
          no: 143,
          name: 'Monaco',
          code: 'mc',
        },
        {
          no: 144,
          name: 'Mongolia',
          code: 'mn',
        },
        {
          no: 145,
          name: 'Montenegro',
          code: 'me',
        },
        {
          no: 146,
          name: 'Montserrat',
          code: 'ms',
        },
        {
          no: 147,
          name: 'Morocco',
          code: 'ma',
        },
        {
          no: 148,
          name: 'Mozambique',
          code: 'mz',
        },
        {
          no: 149,
          name: 'Myanmar',
          code: 'mm',
        },
        {
          no: 150,
          name: 'Namibia',
          code: 'na',
        },
        {
          no: 151,
          name: 'Nauru',
          code: 'nr',
        },
        {
          no: 152,
          name: 'Nepal',
          code: 'np',
        },
        {
          no: 153,
          name: 'Netherlands',
          code: 'nl',
        },
        {
          no: 154,
          name: 'New Caledonia',
          code: 'nc',
        },
        {
          no: 155,
          name: 'New Zealand',
          code: 'nz',
        },
        {
          no: 156,
          name: 'Nicaragua',
          code: 'ni',
        },
        {
          no: 157,
          name: 'Niger',
          code: 'ne',
        },
        {
          no: 158,
          name: 'Nigeria',
          code: 'ng',
        },
        {
          no: 159,
          name: 'Niue',
          code: 'nu',
        },
        {
          no: 160,
          name: 'Norfolk Island',
          code: 'nf',
        },
        {
          no: 161,
          name: 'North Korea',
          code: 'kp',
        },
        {
          no: 162,
          name: 'North Macedonia',
          code: 'mk',
        },
        {
          no: 163,
          name: 'Northern Mariana Islands',
          code: 'mp',
        },
        {
          no: 164,
          name: 'Norway',
          code: 'no',
        },
        {
          no: 165,
          name: 'Oman',
          code: 'om',
        },
        {
          no: 166,
          name: 'Pakistan',
          code: 'pk',
        },
        {
          no: 167,
          name: 'Palau',
          code: 'pw',
        },
        {
          no: 168,
          name: 'Palestine, State of',
          code: 'ps',
        },
        {
          no: 169,
          name: 'Panama',
          code: 'pa',
        },
        {
          no: 170,
          name: 'Papua New Guinea',
          code: 'pg',
        },
        {
          no: 171,
          name: 'Paraguay',
          code: 'py',
        },
        {
          no: 172,
          name: 'Peru',
          code: 'pe',
        },
        {
          no: 173,
          name: 'Philippines',
          code: 'ph',
        },
        {
          no: 174,
          name: 'Pitcairn',
          code: 'pn',
        },
        {
          no: 175,
          name: 'Poland',
          code: 'pl',
        },
        {
          no: 176,
          name: 'Portugal',
          code: 'pt',
        },
        {
          no: 177,
          name: 'Puerto Rico',
          code: 'pr',
        },
        {
          no: 178,
          name: 'Qatar',
          code: 'qa',
        },
        {
          no: 182,
          name: 'RÃ©union',
          code: 're',
        },
        {
          no: 179,
          name: 'Romania',
          code: 'ro',
        },
        {
          no: 180,
          name: 'Russian Federation',
          code: 'ru',
        },
        {
          no: 181,
          name: 'Rwanda',
          code: 'rw',
        },
        {
          no: 183,
          name: 'Saint BarthÃ©lemy',
          code: 'bl',
        },
        {
          no: 184,
          name: 'Saint Helena',
          code: 'sh',
        },
        {
          no: 185,
          name: 'Saint Kitts And Nevis',
          code: 'kn',
        },
        {
          no: 186,
          name: 'Saint Lucia',
          code: 'lc',
        },
        {
          no: 187,
          name: 'Saint Martin',
          code: 'mf',
        },
        {
          no: 188,
          name: 'Saint Pierre And Miquelon',
          code: 'pm',
        },
        {
          no: 189,
          name: 'Saint Vincent And The Grenedines',
          code: 'vc',
        },
        {
          no: 190,
          name: 'Samoa',
          code: 'ws',
        },
        {
          no: 191,
          name: 'San Marino',
          code: 'sm',
        },
        {
          no: 192,
          name: 'Sao Tome and Principe',
          code: 'st',
        },
        {
          no: 193,
          name: 'Saudi Arabia',
          code: 'sa',
        },
        {
          no: 194,
          name: 'Senegal',
          code: 'sn',
        },
        {
          no: 195,
          name: 'Serbia',
          code: 'rs',
        },
        {
          no: 196,
          name: 'Seychelles',
          code: 'sc',
        },
        {
          no: 197,
          name: 'Sierra Leone',
          code: 'sl',
        },
        {
          no: 198,
          name: 'Singapore',
          code: 'sg',
        },
        {
          no: 199,
          name: 'Sint Maarten',
          code: 'sx',
        },
        {
          no: 200,
          name: 'Slovakia',
          code: 'sk',
        },
        {
          no: 201,
          name: 'Slovenia',
          code: 'si',
        },
        {
          no: 202,
          name: 'Solomon Islands',
          code: 'sb',
        },
        {
          no: 203,
          name: 'Somalia',
          code: 'so',
        },
        {
          no: 204,
          name: 'South Africa',
          code: 'za',
        },
        {
          no: 205,
          name: 'South Georgia & South Sandwich Islands',
          code: 'gs',
        },
        {
          no: 206,
          name: 'South Korea',
          code: 'kr',
        },
        {
          no: 207,
          name: 'South Sudan',
          code: 'ss',
        },
        {
          no: 208,
          name: 'Spain',
          code: 'es',
        },
        {
          no: 209,
          name: 'Sri Lanka',
          code: 'lk',
        },
        {
          no: 210,
          name: 'Sudan',
          code: 'sd',
        },
        {
          no: 211,
          name: 'Suriname',
          code: 'sr',
        },
        {
          no: 212,
          name: 'Svalbard And Jan Mayen',
          code: 'sj',
        },
        {
          no: 214,
          name: 'Sweden',
          code: 'se',
        },
        {
          no: 215,
          name: 'Switzerland',
          code: 'ch',
        },
        {
          no: 216,
          name: 'Syrian Arab Republic',
          code: 'sy',
        },
        {
          no: 217,
          name: 'Taiwan',
          code: 'tw',
        },
        {
          no: 218,
          name: 'Tajikistan',
          code: 'tj',
        },
        {
          no: 219,
          name: 'Tanzania, United Republic of',
          code: 'tz',
        },
        {
          no: 220,
          name: 'Thailand',
          code: 'th',
        },
        {
          no: 51,
          name: 'The Dem Republic of Congo',
          code: 'cg',
        },
        {
          no: 221,
          name: 'Timor-Leste',
          code: 'tl',
        },
        {
          no: 222,
          name: 'Togo',
          code: 'tg',
        },
        {
          no: 223,
          name: 'Tokelau',
          code: 'tk',
        },
        {
          no: 224,
          name: 'Tonga',
          code: 'to',
        },
        {
          no: 225,
          name: 'Trinidad and Tobago',
          code: 'tt',
        },
        {
          no: 226,
          name: 'Tunisia',
          code: 'tn',
        },
        {
          no: 227,
          name: 'Turkey',
          code: 'tr',
        },
        {
          no: 228,
          name: 'Turkmenistan',
          code: 'tm',
        },
        {
          no: 229,
          name: 'Turks and Caicos Islands',
          code: 'tc',
        },
        {
          no: 230,
          name: 'Tuvalu',
          code: 'tv',
        },
        {
          no: 231,
          name: 'Uganda',
          code: 'ug',
        },
        {
          no: 232,
          name: 'Ukraine',
          code: 'ua',
        },
        {
          no: 233,
          name: 'United Arab Emirates',
          code: 'ae',
        },
        {
          no: 234,
          name: 'United Kingdom',
          code: 'gb',
        },
        {
          no: 235,
          name: 'United States',
          code: 'us',
        },
        {
          no: 237,
          name: 'Uruguay',
          code: 'uy',
        },
        {
          no: 236,
          name: 'US Minor Outlying Islands',
          code: 'um',
        },
        {
          no: 238,
          name: 'Uzbekistan',
          code: 'uz',
        },
        {
          no: 239,
          name: 'Vanuatu',
          code: 'vu',
        },
        {
          no: 240,
          name: 'Venezuela',
          code: 've',
        },
        {
          no: 241,
          name: 'Vietnam',
          code: 'vn',
        },
        {
          no: 242,
          name: 'Virgin Islands, British',
          code: 'vg',
        },
        {
          no: 243,
          name: 'Virgin Islands, U.S.',
          code: 'vi',
        },
        {
          no: 244,
          name: 'Wallis and Futuna',
          code: 'wf',
        },
        {
          no: 245,
          name: 'Western Sahara',
          code: 'eh',
        },
        {
          no: 246,
          name: 'Yemen',
          code: 'ye',
        },
        {
          no: 247,
          name: 'Zambia',
          code: 'zm',
        },
        {
          no: 248,
          name: 'Zimbabwe',
          code: 'zw',
        },
      ];
      let search = this.$data.mysearch;
      let upperCaseSearch = search.toUpperCase();
      let tmpLength = search.length;

      if (tmpLength == 2) {
        let tmpCountryCode = countryList.filter(function (item) {
          let upperCode = item.code.toUpperCase();
          if (upperCode == upperCaseSearch) {
            return item;
          }
        });
        let tmpCountriesName = countryList.filter(function (item) {
          let upperCaseName = item.name.toUpperCase();
          let upperCode = item.code.toUpperCase();
          if (upperCaseName.includes(upperCaseSearch) && upperCode != upperCaseSearch) {
            return item;
          }
        });
        return tmpCountryCode.concat(tmpCountriesName);
      } else if (tmpLength > 2) {
        return countryList.filter(function (item) {
          let upperCaseName = item.name.toUpperCase();

          if (upperCaseName.includes(upperCaseSearch)) {
            return item;
          }
        });
      } else return countryList;
      // return [];
    },
  },
  async created() {
    // let self = this;
    // let apiUrl = process.env.VUE_APP_API_URL;
    //
    // await fetch(apiUrl + 'common/custom-country-list', {
    //   method: 'GET',
    // })
    //   .then((response) => response.json())
    //   .then((resData) => {
    //     console.log('Success:', resData);
    //     // let messages = resData.messages
    //     if (resData.status == 'error') {
    //       alert('error while geting data ' + resData.message);
    //       return;
    //     }
    //     self.$data.countryList = resData.countryList;
    //   })
    //   .catch(function (err) {
    //     console.log('error', err);
    //   });
  },
};
</script>

<style scoped></style>
